import { Model, Factory, belongsTo, association } from 'miragejs';
import { BelongsTo, ModelInstance } from 'miragejs/-types';

import { ServerWithRegistry } from '../server';

type ModelType = ClientLocaleModel & {
  client: ModelInstance<ClientModel> | BelongsTo<'client'>;
};

// clientId is added and inferred automagically by mirage because client is an assocation
// therefore omit it from the factory typedef, otherwise it will complain when we don't set it
type FactoryType = Omit<ModelType, 'clientId'>;

export const model = Model.extend<ModelType>({
  client: belongsTo<'client'>(),
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',

  client: association(),
  localeChain: 'en-ca,en-us',
  name: 'en-ca',
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/clients/:clientId/locales/:id', (schema, request) =>
    schema.find('clientLocale', request.params.id)
  );
  server.get('/config/clients/:clientId/locales/', (schema) =>
    schema.all('clientLocale')
  );
};
