import type { FilterState } from './filter-state';

const applyCategoryFilter = (filterState: FilterState) => (
  category: CategoryModel
) => {
  let entries = category.contentEntries;

  // filter by state
  if (filterState.onlyIncomplete) {
    entries = entries.filter((entry) => !entry.complete);
  }

  if (filterState.status.length > 0) {
    entries = entries.filter((entry) =>
      filterState.status?.includes(entry.status!)
    );
  }

  if (filterState.spaces.length > 0) {
    entries = entries.filter((entry) =>
      filterState.spaces?.includes(entry.spaceId)
    );
  }

  if (filterState.contentTypes.length > 0) {
    entries = entries.filter((entry) =>
      filterState.contentTypes?.includes(entry.contentTypeId)
    );
  }

  // filter by content title
  if (filterState.contentTitle) {
    entries = entries.filter((entry) =>
      entry.title
        ?.toLowerCase()
        .includes(filterState.contentTitle.toLowerCase())
    );
  }

  // filter by topic
  if (filterState.topics.length > 0) {
    entries = entries.filter((entry) =>
      filterState.topics.includes(entry.topicId!)
    );
  }

  // filter by attributes
  const attributeKeys: (keyof ReferenceAttributes)[] = [
    'deviceIds',
    'deviceTypeIds',
    'manufacturerIds',
    'osIds',
    'osReleaseIds',
    'osVersionIds',
  ];
  attributeKeys.forEach((key) => {
    const attrIds = filterState[key];
    if (Array.isArray(attrIds) && attrIds.length > 0) {
      entries = entries.filter((entry) =>
        entry[key].some((id) => attrIds.includes(Number(id)))
      );
    }
  });

  entries = entries.sort((a, b) => {
    const aValue = a[filterState.sortField]?.toLocaleString() ?? '';
    const bValue = b[filterState.sortField]?.toLocaleString() ?? '';

    let sortA = aValue;
    let sortB = bValue;
    if (filterState.sortDirection === 'desc') {
      sortA = bValue;
      sortB = aValue;
    }

    if (['addedAt', 'createdAt', 'updatedAt'].includes(filterState.sortField)) {
      return new Date(sortA).getTime() - new Date(sortB).getTime();
    }

    return sortA.localeCompare(sortB);
  });

  return {
    ...category,
    contentEntries: entries,
  };
};

export default applyCategoryFilter;
