import { Model, Factory } from 'miragejs';

import { ServerWithRegistry } from '../server';

export const model = Model.extend<OperatingSystemModel>(
  // @ts-expect-error
  {}
);

export const factory = Factory.extend<OperatingSystemModel, ServerWithRegistry>(
  {
    id: (i) => i + 1,
    createdAt: () => '2023-04-12',
    updatedAt: () => '2023-04-12',
    name: (i) => `operating system name ${i}`,
    displayOrder: () => 0,
    shouldDisplayReleaseName: false,
  }
);

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/operating_systems/:id', (schema, request) =>
    schema.find('operatingSystem', request.params.id)
  );
  server.get('/config/operating_systems/', (schema) =>
    schema.all('operatingSystem')
  );
};
