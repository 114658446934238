import { Model, Factory, association, Collection, belongsTo } from 'miragejs';
import { ModelInstance } from 'miragejs/-types';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';

import { ServerWithRegistry } from '../server';
import { applicationSerializer } from '../serializer'; // Required for the custom serializer

export type ModelType = Omit<DeviceModel, 'manufacturer' | 'deviceType'> & {
  deviceType: ModelInstance<DeviceTypeModel>;
  manufacturer: ModelInstance<ManufacturerModel>;
};

type FactoryType = Omit<ModelType, 'deviceTypeId' | 'manufacturerId'>;

export const model = Model.extend<ModelType>({
  deviceType: belongsTo() as any,
  manufacturer: belongsTo() as any,
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  categoryMapId: () => 1,
  contentType: () => 'Interactive Tutorial',
  deviceImagePath: () => '/mock/device/image/path.jpg',
  displayOrder: () => 0,
  linkedOperatingSystem: () => '1.2.3',
  linkedOperatingSystemId: () => 1,
  operatingSystem: () => 'OS 123',
  operatingSystemId: () => 1,
  thumbnailPath: () => '/mock/path/to/thumbnail.jpb',
  trackingName: (i) => `tracking-name-${i}`,
  paidStatus: () => 'paid',

  manufacturer: association(),
  deviceType: association(),
});

// Override the serializer for this model because the Ozmo API returns the string name
// of the Client instead of the actual client model, unlike most authoring relations
export const serializer = applicationSerializer.extend({
  serialize(
    device: Collection<ModelInstance<FactoryType>> | ModelInstance<FactoryType>,
    request: Request
  ) {
    // @ts-expect-error - prototype isn't typed
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [device, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(device)) {
      return (serializedData as FactoryType[]).map((o, i) => {
        return {
          ...o,
          deviceType: device.models[i].deviceType.name,
          manufacturer: device.models[i].manufacturer.name,
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...device.attrs,
      ...serializedData,
      deviceType: device.deviceType.name,
      manufacturer: device.manufacturer.name,
    };
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/devices/:id', (schema, request) =>
    schema.find('device', request.params.id)
  );
  server.get('/config/devices/', (schema) => schema.all('device'));
};
