import { Model, Factory, belongsTo, association, Collection } from 'miragejs';
import { BelongsTo, ModelInstance } from 'miragejs/-types';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';

import { ServerWithRegistry } from '../server';
import { applicationSerializer } from '../serializer';

export type ModelType = Omit<OperatingSystemReleaseModel, 'operatingSystem'> & {
  operatingSystem:
    | ModelInstance<OperatingSystemModel>
    | BelongsTo<'operatingSystem'>;
};

// operatingSystemId is added and inferred automagically by mirage because operatingSystem is an assocation
// therefore omit it from the factory typedef, otherwise it will complain when we don't set it
type FactoryType = Omit<OperatingSystemReleaseModel, 'operatingSystemId'> & {
  operatingSystem: ModelInstance<OperatingSystemModel>;
};

export const model = Model.extend<ModelType>({
  operatingSystem: belongsTo<'operatingSystem'>(),
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `os release name ${i}`,
  displayOrder: 0,
  operatingSystemName: '',

  operatingSystem: association(),
});

export const serializer = applicationSerializer.extend({
  serialize(
    operatingSystemRelease:
      | Collection<ModelInstance<FactoryType>>
      | ModelInstance<FactoryType>,
    request: Request
  ) {
    // @ts-expect-error
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [operatingSystemRelease, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(operatingSystemRelease)) {
      return (serializedData as FactoryType[]).map((o, i) => {
        return {
          ...o,
          operatingSystemName:
            operatingSystemRelease.models[i].operatingSystem.name,
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...operatingSystemRelease.attrs,
      ...serializedData,
      operatingSystemName: operatingSystemRelease.operatingSystem.name,
    };
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/operating_system_releases/:id', (schema, request) =>
    schema.find('operatingSystemRelease', request.params.id)
  );
  server.get('/config/operating_system_releases/', (schema) =>
    schema.all('operatingSystemRelease')
  );
};
