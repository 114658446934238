import { Request, RestSerializer } from 'miragejs';
import { toCamel } from 'services/utils/convert-object-keys-snake-to-camel';
import convertCamelToSnakeCase from 'services/utils/convert-object-keys-camel-to-snake';

// Very annoyingly, miragejs stores IDs as strings ("1" instead of 1)
// So as a part of the serialization of database data to an API response
// we need to convert them to numbers, otherwise we'll get all kinds of validation errors
// from Typescript and at runtime from PropTypes (and who knows what other bugs)
export const toNumber = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.map((v) => {
      const number = parseInt(v, 10);
      return Number.isNaN(number) ? value : number;
    });
  }
  const number = parseInt(value, 10);
  return Number.isNaN(number) ? value : number;
};

export const responseIdStringToNumber = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((o) => responseIdStringToNumber(o));
  }
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    // There are three types of ID we need to convert:
    // 1) Primary keys on a model (device.id)
    // 2) Foreign keys to other models (device.operatingSystemReleaseId)
    // 3) Arrays of primary keys (deviceIds: [])
    if (key.match(/(^id$)|(Id$)|(Ids$)/)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = toNumber(value);
    } else if (Array.isArray(value)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value.map((o) => responseIdStringToNumber(o));
    } else if (typeof value === 'object' && value !== null) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = responseIdStringToNumber(value);
    }
  });

  return obj;
};

export const convertEmbedToInlcude = (request: Request) => {
  const { embed = '' } = request.queryParams || {};

  // Do not try to embed localizedReferences, because that is a weirdo format that does
  // not follow the normal REST embed standard, so the serializer will blow up.  Instead
  // the factory trait for asCollection sets the field with the correct value directly
  return (embed as string)
    .split(',')
    .filter((param: string) => param !== 'localized_references')
    .map((e: string) => toCamel(e) as string);
};

export const applicationSerializer = RestSerializer.extend({
  embed: true,
  root: false,
  serializeIds: 'always',
  include: (request: Request) => convertEmbedToInlcude(request),
  serialize(object, request) {
    // @ts-expect-error
    const serializedJson = RestSerializer.prototype.serialize.apply(this, [
      object,
      request,
    ]);
    if (Array.isArray(serializedJson)) {
      const mergedJson = serializedJson.map((o, i) => ({
        ...o,
        ...object.models[i].attrs,
      }));
      const mergedJsonWithNumericIds = responseIdStringToNumber(mergedJson);
      return convertCamelToSnakeCase(mergedJsonWithNumericIds);
    }
    const mergedJson = { ...object.attrs, ...serializedJson };
    const mergedJsonWithNumericIds = responseIdStringToNumber(mergedJson);
    return convertCamelToSnakeCase(mergedJsonWithNumericIds);
  },
}) as typeof RestSerializer;
