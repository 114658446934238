import { Collection } from 'miragejs';
import { ModelInstance } from 'miragejs/-types';

export function isMirageCollection<T extends {}>(
  collectionOrArray?: Collection<T> | ModelInstance<T>[] | ModelInstance<T>
): collectionOrArray is Collection<T> {
  return (collectionOrArray as Collection<T>).models !== undefined;
}

export function isArrayOfModels<T extends {}>(
  collectionOrArray?: Collection<T> | ModelInstance<T>[]
): collectionOrArray is ModelInstance<T>[] {
  return (collectionOrArray as Collection<T>).models === undefined;
}
