import { Model, Factory } from 'miragejs';

import { ServerWithRegistry } from '../server';

type ModelWithTraits = ContentTypeModel & {
  traits?: {};
};

export const model = Model.extend<ModelWithTraits>(
  // @ts-expect-error
  {}
);

export const factory = Factory.extend<ModelWithTraits, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `Mock name ${i}`,
  displayName: (i) => `Mock display name ${i}`,
  description: (i) => `Mock description ${i}`,
  isProductionReady: () => true,
  contentSchema: {
    fields: [
      {
        name: 'problem',
        editor: {
          displayName: 'Problem statement',
          description: 'Description of the problem the customer is facing',
          displayOrder: 0,
        },
        type: 'String',
        required: true,
      },
      {
        name: 'description',
        editor: {
          displayName: 'Description',
          description: 'An extended description of the problem',
          displayOrder: 1,
        },
        type: 'Text',
      },
    ],
  },
  metadataSchema: {},
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/authoring/content_types/:id', (schema, request) =>
    schema.find('contentType', request.params.id)
  );
  server.get('/authoring/content_types/', (schema) =>
    schema.all('contentType')
  );
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.create('contentType', {
    name: 'interactiveTutorial',
    displayName: 'Tutorial',
  });
  server.create('contentType', {
    name: 'pointsOfInterest',
    displayName: 'Points of Interest',
  });
  server.create('contentType', {
    name: 'Collection',
    displayName: 'Collection',
  });
  server.create('contentType', {
    name: 'legacyInteractiveTutorial',
    displayName: 'Legacy Tutorial',
  });
  server.create('contentType', {
    name: 'legacyPointsOfInterest',
    displayName: 'Legacy Points of Interest',
  });
  server.create('contentType', {
    name: 'tour',
    displayName: 'Tour',
  });
};
