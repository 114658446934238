import { Model, Factory, Response } from 'miragejs';

import { ServerWithRegistry } from '../server';

export const model = Model.extend<LanguageModel>({} as any);

export const factory = Factory.extend<LanguageModel, ServerWithRegistry>({
  id: (i: number) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: () => 'English',
  description: (i) => `Mock description ${i}`,
  displayName: () => 'English',
  shortCode: () => 'en',
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/languages/:id', (schema, request) =>
    schema.find('language', request.params.id)
  );
  server.get('/config/languages/', (schema) => schema.all('language'));

  server.patch('config/languages/:id', (schema, request) => {
    const { id } = request.params;
    const body = JSON.parse(request.requestBody);

    const language = schema.find('language', id);
    if (!language) {
      return new Response(404);
    }

    language.update(body);
    return language;
  });

  server.post('config/languages', (schema, request) => {
    const body = JSON.parse(request.requestBody);
    return schema.create('language', body);
  });

  server.del('config/languages/:id', (schema, request) => {
    const language = schema.find('language', request.params.id);

    if (!language) {
      return new Response(404);
    }
    language.destroy();
    return new Response(204);
  });
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.create('language', {
    name: 'English',
    displayName: 'English',
    shortCode: 'en',
  });
  server.create('language', {
    name: 'Français',
    displayName: 'French',
    shortCode: 'fr',
  });
  server.create('language', {
    name: 'Español',
    displayName: 'Spanish',
    shortCode: 'es',
  });
  server.create('language', {
    name: '日本語',
    displayName: 'Japanese',
    shortCode: 'ja',
  });
};
