import {
  Model,
  Factory,
  hasMany,
  ModelInstance,
  Response,
  Request,
  Collection,
  belongsTo,
  association,
} from 'miragejs';
import { BelongsTo, HasMany } from 'miragejs/-types';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';
import keysToCamel from 'services/utils/convert-object-keys-snake-to-camel';

import { ServerWithRegistry } from '../server';
// Required for the custom serializer
import { applicationSerializer } from '../serializer';

// Mirage override types for specific models
import { ModelType as MirageDeviceModel } from './device';
import { ModelType as MirageOperatingSystemVersionModel } from './operating-system-version';
import {
  ModelType as MirageLocalizedContentEntryModel,
  serializeIfExists as serializeLocalizedContentEntryIfExists,
} from './localized-content-entry';
import { serializeIfExists as serializeSpaceIfExists } from './space';

type SharedModelAndFactory = Omit<
  ContentEntryModel,
  | 'contentType'
  | 'devices'
  | 'deviceTypes'
  | 'localizedContentEntries'
  | 'manufacturers'
  | 'operatingSystems'
  | 'operatingSystemReleases'
  | 'operatingSystemVersions'
  | 'references'
  | 'space'
  | 'topic'
> & {
  deviceIds?: number[];
  deviceTypeIds?: number[];
  manufacturerIds?: number[];
  operatingSystemIds?: number[];
  operatingSystemReleaseIds?: number[];
  operatingSystemVersionIds?: number[];
  topicId?: number;
  traits?: {
    withDevices?: boolean;
    withDeviceTypes?: boolean;
    withManufacturers?: boolean;
    withLocalizedContentEntries?: boolean;
    withOperatingSystems?: boolean;
    withOperatingSystemReleases?: boolean;
    withOperatingSystemVersions?: boolean;
    langaugeId?: number;
  };
};

// Model hasMany properties return a Collection of <Model>
export type ModelWithRelationships = SharedModelAndFactory & {
  contentType: ModelInstance<ContentTypeModel> | BelongsTo<'contentType'>;
  devices?:
    | Collection<DeviceModel>
    | ModelInstance<MirageDeviceModel>[]
    | HasMany<'device'>;
  deviceTypes?:
    | Collection<DeviceTypeModel>
    | ModelInstance<DeviceTypeModel>[]
    | HasMany<'deviceType'>;
  manufacturers?:
    | Collection<ManufacturerModel>
    | ModelInstance<ManufacturerModel>[]
    | HasMany<'manufacturer'>;
  localizedContentEntries?:
    | Collection<LocalizedContentEntryModel>
    | ModelInstance<MirageLocalizedContentEntryModel>[]
    | HasMany<'localizedContentEntry'>;
  operatingSystems?:
    | Collection<OperatingSystemModel>
    | ModelInstance<OperatingSystemModel>[]
    | HasMany<'operatingSystem'>;
  operatingSystemReleases?:
    | Collection<OperatingSystemReleaseModel>
    | ModelInstance<OperatingSystemReleaseModel>[]
    | HasMany<'operatingSystemRelease'>;
  operatingSystemVersions?:
    | Collection<OperatingSystemVersionModel>
    | ModelInstance<MirageOperatingSystemVersionModel>[]
    | HasMany<'operatingSystemVersion'>;
  space?: ModelInstance<SpaceModel> | BelongsTo<'space'>;
  topic?: ModelInstance<TopicModel> | BelongsTo<'topic'>;
  references?: BelongsTo<any>;
};

// Factory methods for hasMany properties take an array of <Model>
type FactoryModel = Omit<
  SharedModelAndFactory,
  'contentTypeId' | 'spaceId' | 'topicId'
> & {
  contentType: ModelInstance<ContentTypeModel>;
  devices?: ModelInstance<DeviceModel>[] | Collection<DeviceModel>;
  deviceTypes?: ModelInstance<DeviceTypeModel>[];
  localizedContentEntries?: ModelInstance<MirageLocalizedContentEntryModel>[];
  manufacturers?: ModelInstance<ManufacturerModel>[];
  operatingSystems?: ModelInstance<OperatingSystemModel>[];
  operatingSystemReleases?: ModelInstance<OperatingSystemReleaseModel>[];
  operatingSystemVersions?: ModelInstance<OperatingSystemVersionModel>[];
  space?: ModelInstance<SpaceModel>;
  topic?: ModelInstance<TopicModel>;
};

export const model = Model.extend<ModelWithRelationships>({
  contentType: belongsTo<'contentType'>(),
  space: belongsTo<'space'>(),
  topic: belongsTo<'topic'>(),
  references: belongsTo(),

  devices: hasMany<'device'>(),
  deviceTypes: hasMany<'deviceType'>(),
  manufacturers: hasMany<'manufacturer'>(),
  localizedContentEntries: hasMany<'localizedContentEntry'>(),
  operatingSystems: hasMany<'operatingSystem'>(),
  operatingSystemReleases: hasMany<'operatingSystemRelease'>(),
  operatingSystemVersions: hasMany<'operatingSystemVersion'>(),
} as any);

export const factory = Factory.extend<FactoryModel, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-02-19T17:32:56.060Z',
  updatedAt: () => '2023-02-19T17:32:56.060Z',
  audience: (i) => ['customers', 'agents', ''][i % 3],
  featureId: (i) => `FEATURE_TEST_${i}`.toUpperCase().replace(/\s/g, ''),
  metadata: {},
  proficiencyLevel: (i) => ['advanced', 'beginner'][i % 2],
  title: (i) => `Mock title ${i}`,
  tags: [],

  contentType: association(),
  space: association(),
  topic: association(),

  afterCreate(contentEntry, server) {
    const {
      contentType,
      traits: {
        withDevices,
        withDeviceTypes,
        withManufacturers,
        withLocalizedContentEntries,
        withOperatingSystems,
        withOperatingSystemReleases,
        withOperatingSystemVersions,
      } = {},
    } = contentEntry;

    if (withLocalizedContentEntries) {
      switch (contentType.name) {
        case 'collection': {
          contentEntry.update({
            localizedContentEntries: server.createList(
              'localizedContentEntry',
              3,
              { traits: { asCollection: true } }
            ),
          });
          break;
        }
        case 'pointsOfInterest': {
          contentEntry.update({
            localizedContentEntries: server.createList(
              'localizedContentEntry',
              3,
              { traits: { asPointsOfInterest: true } }
            ),
          });
          break;
        }
        case 'interactiveTutorial':
        default: {
          contentEntry.update({
            localizedContentEntries: [
              server.create('localizedContentEntry', {
                language: server.schema.findOrCreateBy('language', {
                  name: 'English',
                }),
                traits: { asInteractiveTutorial: true },
              }),
              server.create('localizedContentEntry', {
                language: server.schema.findOrCreateBy('language', {
                  name: 'French',
                }),
                traits: { asInteractiveTutorial: true },
              }),
              server.create('localizedContentEntry', {
                language: server.schema.findOrCreateBy('language', {
                  name: 'Spanish',
                }),
                traits: { asInteractiveTutorial: true },
              }),
            ],
          });
          break;
        }
      }
    }

    if (withDevices) {
      contentEntry.update({
        devices: server.createList('device', 3),
      });
    }

    if (withDeviceTypes) {
      contentEntry.update({
        deviceTypes: server.createList('deviceType', 3),
      });
    }

    if (withManufacturers) {
      contentEntry.update({
        manufacturers: server.createList('manufacturer', 3),
      });
    }

    if (withOperatingSystems) {
      contentEntry.update({
        operatingSystems: server.createList('operatingSystem', 3),
      });
    }

    if (withOperatingSystemReleases) {
      contentEntry.update({
        operatingSystemReleases: server.createList('operatingSystemRelease', 3),
      });
    }

    if (withOperatingSystemVersions) {
      contentEntry.update({
        operatingSystemVersions: server.createList('operatingSystemVersion', 3),
      });
    }
  },
});

// Override the serializer for this model because the Ozmo API returns the string name
// of the ContentType instead of the actual ContentType model, unlike most authoring relations
export const serializer = applicationSerializer.extend({
  serialize(
    contentEntry:
      | Collection<ModelInstance<FactoryModel>>
      | ModelInstance<FactoryModel>,
    request: Request
  ) {
    // @ts-expect-error
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [contentEntry, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(contentEntry)) {
      return (serializedData as FactoryModel[]).map((o, i) => {
        const [model] = contentEntry.models;
        return {
          ...o,
          // Ozmo API returns the string name of the contentType, NOT the client object
          contentType: model.contentType?.name ?? undefined,
          localizedContentEntries: serializeLocalizedContentEntryIfExists(
            model.localizedContentEntries,
            request,
            this
          ),
          space: serializeSpaceIfExists(model.space, request, this),
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...contentEntry.attrs,
      ...serializedData,
      // Ozmo API returns the string name of the client, NOT the client object
      contentType: contentEntry.contentType?.name ?? undefined,
      localizedContentEntries: serializeLocalizedContentEntryIfExists(
        contentEntry.localizedContentEntries,
        request,
        this
      ),
      space: serializeSpaceIfExists(contentEntry.space, request, this),
    };
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/authoring/content_entries/:id', (schema, request) =>
    schema.find('contentEntry', request.params.id)
  );
  server.get('/authoring/content_entries/', (schema) =>
    schema.all('contentEntry')
  );

  // update
  server.patch('authoring/content_entries/:id', (schema, request) => {
    const { id } = request.params;
    const body = JSON.parse(request.requestBody);

    const contentEntry = schema.find('contentEntry', id);
    if (!contentEntry) {
      return new Response(404);
    }

    contentEntry.update(body);
    return contentEntry;
  });

  // create or bulk action
  server.post('authoring/content_entries', (schema, request) => {
    const { 'Content-Type': contentType } = request.requestHeaders;

    // if the content-type is 'application/x-ndjson', it is a bulk action request
    // as such, return a job id
    if (contentType === 'application/x-ndjson') {
      return {
        job: 'jobid-123-456',
      };
    }

    const body = JSON.parse(request.requestBody);

    // Yes, this is hacky. I tried to reassign the content entry post within playwright,
    // but it would always use this mock response instead. So I'm getting around that by
    // checking the title and returning a duplicate error if it matches.
    if (body.title == 'test-return-duplicate-error') {
      return new Response(
        422,
        { 'Content-Type': 'application/json' },
        JSON.stringify({
          message: 'DuplicateContentEntry',
          detail: {
            id: schema.first('contentEntry')?.id,
            language_ids: [1],
            reference_count: 0,
          },
        })
      );
    }

    // otherwise assume create
    return schema.create('contentEntry', body);
  });

  // copy
  server.post('authoring/content_entries/:id/copy', (schema, request) => {
    const body = keysToCamel(JSON.parse(request.requestBody));
    const duplicateSpace = schema.where('space', {
      name: 'test-return-duplicate-error space',
    });

    // Yes, this is hacky. I tried to reassign the content entry post within playwright,
    // but it would always use this mock response instead. So I'm getting around that by
    // checking the space and returning a duplicate error if it matches.
    if (
      duplicateSpace?.models.length > 0 &&
      body.spaceIds.includes(Number(duplicateSpace.models[0].attrs.id))
    ) {
      return new Response(
        422,
        { 'Content-Type': 'application/json' },
        JSON.stringify({
          message: 'DuplicateContentEntry',
          detail: {
            id: schema.first('contentEntry')?.id,
            language_ids: [1],
            reference_count: 0,
          },
        })
      );
    }

    const contentEntries = body.spaceIds.map((spaceId: number) =>
      schema.create('contentEntry', { ...body, spaceId })
    );
    return contentEntries;
  });

  // search
  server.post('authoring/content_entries/search', (schema, request) => {
    const body = JSON.parse(request.requestBody);

    const contentEntries = body.device_ids
      ? schema.where('contentEntry', {
          deviceIds: body.device_ids,
        })
      : schema.all('contentEntry');

    return contentEntries.models.map((c) => ({
      id: parseInt(c.id, 10),
      featureId: c.featureId,
      contentType: c.contentType?.name,
      contentTypeId: c.contentTypeId,
      locales: (c.localizedContentEntries ?? ([] as any)).models.map(
        ({ locale, localeId }: MirageLocalizedContentEntryModel) => ({
          id: localeId,
          name: locale.name,
        })
      ),
      languages: (c.localizedContentEntries ?? ([] as any)).models.map(
        ({
          languageId,
          language,
          languageDisplayName,
          languageShortCode,
        }: MirageLocalizedContentEntryModel) => ({
          id: languageId,
          name: language,
          displayName: languageDisplayName,
          shortCode: languageShortCode,
        })
      ),
      devices: (c.devices ?? ([] as any)).models.map(
        ({ id, trackingName }: DeviceModel) => ({
          id,
          name: trackingName,
        })
      ),
      deviceTypes: (c.deviceTypes ?? ([] as any)).models.map(
        ({ id, name }: DeviceTypeModel) => ({
          id,
          name,
        })
      ),
      manufacturers: (c.manufacturers ?? ([] as any)).models.map(
        ({ id, name }: ManufacturerModel) => ({
          id,
          name,
        })
      ),
      operatingSystems: (c.operatingSystems ?? ([] as any)).models.map(
        ({ id, name }: OperatingSystemModel) => ({
          id,
          name,
        })
      ),
      operatingSystemReleases: (
        c.operatingSystemReleases ?? ([] as any)
      ).models.map(({ id, name }: OperatingSystemModel) => ({
        id,
        name,
      })),
      operatingSystemVersions: (
        c.operatingSystemVersions ?? ([] as any)
      ).models.map(({ id, name }: OperatingSystemVersionModel) => ({
        id,
        name,
      })),
    }));
  });

  server.del('authoring/content_entries/:id', (schema, request) => {
    schema.find('contentEntry', request.params.id)?.destroy();
    return new Response(204);
  });

  // translations
  server.post('authoring/content_entries/translations/export', () => {
    // generic translation export as if going from en-us to es-us
    return {
      'en-us_to_es-us': {
        content_entry_id: 1,
        content_type_id: 1,
        content_type: 'interactiveTutorial',
        source_locale_id: 1,
        source_locale: 'en-us',
        target_locale_id: 2,
        target_locale: 'es-us',
        fields: {
          notes: [
            {
              source: 'Test feature note EN',
              target: 'Test feature note ES',
            },
          ],
          steps: [
            {
              notes: [
                {
                  source: 'Test note EN',
                  target: 'Test note ES',
                },
              ],
              command: {
                source: 'Test step EN.',
                target: 'Test step ES.',
              },
            },
          ],
        },
      },
    };
  });

  server.patch('authoring/content_entries/translations/import', () => {
    // just mocking that the import was successful
    return new Response(200);
  });

  // simple mock endpoint for HOAs to always simply add a valid OS
  server.post(
    'authoring/content_entries/higher_order_attributes',
    (schema, request) => {
      const body = JSON.parse(request.requestBody);
      const os = schema.first('operatingSystem');
      const result = {
        ...body,
        operatingSystemIds: [
          ...(body.operatingSystemIds || []),
          ...[os ? Number(os.id) : undefined].filter((id) => id),
        ],
      };

      return result;
    }
  );
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.createList('contentEntry', 5, {
    traits: {
      withDevices: true,
      withDeviceTypes: true,
      withManufacturers: true,
      withOperatingSystems: true,
      withOperatingSystemReleases: true,
      withOperatingSystemVersions: true,
      withLocalizedContentEntries: true,
    },
  });
};
