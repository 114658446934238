import {
  Model,
  Factory,
  ModelInstance,
  hasMany,
  Collection,
  Response,
} from 'miragejs';
import { Instantiate, HasMany } from 'miragejs/-types';
import keysToCamel from 'services/utils/convert-object-keys-snake-to-camel';

import { ServerRegistry, ServerWithRegistry } from '../server';

// Mirage override types for specific models
import { ModelType as CategoryModelType } from './category';
import { ModelType as MirageDeviceModel } from './device';
import { ModelType as MirageOperatingSystemVersionModel } from './operating-system-version';

const extractAttributes = (ce: any): ReferenceAttributes => {
  return {
    deviceIds: (ce.devices ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    deviceTypeIds: (ce.deviceTypes ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    manufacturerIds: (ce.manufacturers ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    osIds: (ce.operatingSystems ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    osReleaseIds: (ce.operatingSystemReleases ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    osVersionIds: (ce.operatingSystemVersions ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
  };
};

type ModelWithRelationships = Omit<CollectionModel, 'categories'> & {
  categories: Collection<CategoryModelType>;
  devices?:
    | Collection<DeviceModel>
    | ModelInstance<MirageDeviceModel>[]
    | HasMany<'device'>;
  deviceTypes?:
    | Collection<DeviceTypeModel>
    | ModelInstance<DeviceTypeModel>[]
    | HasMany<'deviceTypes'>;
  manufacturers?:
    | Collection<ManufacturerModel>
    | ModelInstance<ManufacturerModel>[]
    | HasMany<'manufacturer'>;
  operatingSystems?:
    | Collection<OperatingSystemModel>
    | ModelInstance<OperatingSystemModel>[]
    | HasMany<'operatingSystem'>;
  operatingSystemReleases?:
    | Collection<OperatingSystemReleaseModel>
    | ModelInstance<OperatingSystemReleaseModel>[]
    | HasMany<'operatingSystemRelease'>;
  operatingSystemVersions?:
    | Collection<OperatingSystemVersionModel>
    | ModelInstance<MirageOperatingSystemVersionModel>[]
    | HasMany<'operatingSystemVersion'>;

  traits?: {
    withData?: boolean;
    languageId?: number;
  };
};

// Factory methods for hasMany properties take an array of <Model>
type FactoryType = Omit<ModelWithRelationships, 'categories'> & {
  devices?: ModelInstance<DeviceModel>[] | Collection<DeviceModel>;
  deviceTypes?: ModelInstance<DeviceTypeModel>[];
  manufacturers?: ModelInstance<ManufacturerModel>[];
  operatingSystems?: ModelInstance<OperatingSystemModel>[];
  operatingSystemReleases?: ModelInstance<OperatingSystemReleaseModel>[];
  operatingSystemVersions?: ModelInstance<OperatingSystemVersionModel>[];
  categories?: ModelInstance<CategoryModel>[] | Collection<CategoryModel>;
};

export const model = Model.extend<ModelWithRelationships>({
  categories: hasMany<'category'>(),

  // attributes
  devices: hasMany<'device'>(),
  deviceTypes: hasMany<'deviceType'>(),
  manufacturers: hasMany<'manufacturer'>(),
  operatingSystems: hasMany<'operatingSystem'>(),
  operatingSystemReleases: hasMany<'operatingSystemRelease'>(),
  operatingSystemVersions: hasMany<'operatingSystemVersion'>(),
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `Collection ${i}`,

  afterCreate: (collection, server) => {
    const { traits: { withData, languageId } = {} } = collection;

    if (withData) {
      const contentType = server.schema.findOrCreateBy('contentType', {
        name: 'interactiveTutorial',
      });
      // Create 3 categories, each with 3 unique content entries
      // one set with have manufacturers, another devices, and the last with no attributes
      collection.update({
        categories: [
          server.create('category', {
            contentEntries: server.createList('contentEntry', 3, {
              contentType,
              traits: {
                withLocalizedContentEntries: true,
                withManufacturers: true,
                languageId,
              },
            }) as any,
          }),
          server.create('category', {
            contentEntries: server.createList('contentEntry', 3, {
              contentType,
              traits: {
                withLocalizedContentEntries: true,
                withDevices: true,
                languageId,
              },
            }) as any,
          }),
          server.create('category', {
            contentEntries: server.createList('contentEntry', 3, {
              contentType,
              traits: { withLocalizedContentEntries: true, languageId },
            }) as any,
          }) as any,
        ],
      });
    }
  },
});

const serializeAttributeCollection = <A extends Attribute>(
  attributes: Collection<ModelInstance<A>>
) =>
  attributes?.models.map((attribute) => ({
    ...attribute.attrs,
    id: Number(attribute.id),
  }));

const serializeWithLanguage = (
  collection: ModelWithRelationships,
  languageId: number
): CollectionModel => ({
  id: Number(collection.id),
  createdAt: collection.createdAt,
  updatedAt: collection.updatedAt,
  name: collection.name,
  categories: collection.categories.models.map((cat) => ({
    id: Number(cat.id),
    collectionId: Number(collection.id),
    parentId: null,
    createdAt: cat.createdAt,
    updatedAt: cat.updatedAt,
    name: cat.name,
    contentEntries: cat.contentEntries.models.map((ce) => {
      const [lce] = (ce.localizedContentEntries as any).models.filter(
        (l: LocalizedContentEntryModel) =>
          parseInt(l.languageId.toString(), 10) === languageId
      ) as Instantiate<ServerRegistry, 'localizedContentEntry'>[];

      return {
        id: Number(ce.id),
        createdAt: ce.createdAt,
        updatedAt: ce.updatedAt,
        contentType: ((ce.contentType as unknown) as ContentTypeModel).name,
        contentTypeId: ce.contentTypeId,
        space: ce.space!.name,
        spaceId: Number(ce.space!.id),
        topic: ce.topic!.title,
        topicSlug: ce.topic!.slug,
        topicId: Number(ce.topic!.id),
        localizedContentEntryId: lce ? Number(lce.id) : null,
        locale: lce ? lce.locale.name : null,
        localeId: lce ? Number(lce.locale.id) : null,
        language: lce?.language?.name || null,
        languageShortCode: lce?.languageShortCode ?? null,
        languageId: lce ? Number(lce.languageId) : null,
        status: lce?.status ?? null,
        complete: lce?.complete ?? null,
        title: lce?.properties?.title ?? null,
        description: lce?.properties?.description ?? null,
        deletedAt: lce?.deletedAt ?? null,
        ...extractAttributes(ce),
      } as LocalizedCollectionReference;
    }),
  })),
  devices: serializeAttributeCollection(collection.devices as any),
  deviceTypes: serializeAttributeCollection(collection.deviceTypes as any),
  manufacturers: serializeAttributeCollection(collection.manufacturers as any),
  operatingSystems: serializeAttributeCollection(
    collection.operatingSystems as any
  ),
  operatingSystemReleases: serializeAttributeCollection(
    collection.operatingSystemReleases as any
  ),
  operatingSystemVersions: serializeAttributeCollection(
    collection.operatingSystemVersions as any
  ),
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/authoring/collections/:id', (schema, request) => {
    const {
      params: { id },
      queryParams: { embed, language_id: languageId } = {},
    } = request;

    const collection = (schema.find(
      'collection2',
      id
    ) as unknown) as ModelWithRelationships;
    if (embed!.includes('categories') && languageId && collection) {
      return serializeWithLanguage(
        collection,
        parseInt(languageId as string, 10)
      );
    }
    return collection;
  });
  server.get('/authoring/collections/', (schema, request) => {
    const {
      queryParams: { referenced_content_entry_id: refId } = {},
    } = request;
    if (refId) {
      return schema
        .all('collection2')
        .models.filter(
          (collection) =>
            !collection.categories.models.some((cat: any) =>
              cat.contentEntries.models.find(
                ({ id }: any) => Number(id) === Number(refId)
              )
            )
        );
    }
    return schema.all('collection2');
  });
  server.patch('/authoring/collections/:id', (schema, request) => {
    const collection = schema.find('collection2', request.params?.id);
    collection?.update(JSON.parse(request.requestBody));
    return collection;
  });
  server.post('/authoring/collections/', (schema, request) => {
    const body = keysToCamel(JSON.parse(request.requestBody));
    return schema.create('collection2', body);
  });
  server.delete('authoring/collections/:id', (schema, request) => {
    const collection = schema.find('collection2', request.params?.id);
    collection?.destroy();
    return new Response(204);
  });
  server.post('authoring/collections/:id/copy', (schema, request) => {
    const collection = schema.find('collection2', request.params?.id);
    const params = { ...collection };
    return schema.create('collection2', params as any);
  });

  // search
  server.post('authoring/collections/search', (schema, request) => {
    const body = JSON.parse(request.requestBody);

    const collections = body.device_ids
      ? schema.where(
          'collection2',
          (collection) =>
            Array.isArray(collection.devices) &&
            collection.devices?.some((d) => body.device_ids.includes(d.id))
        )
      : schema.all('collection2');

    return collections.models.map((c) => ({
      id: parseInt(c.id!, 10),
      updatedAt: c.updatedAt,
      createdAt: c.createdAt,
      title: c.name,
      devices: (c.devices ?? ([] as any)).models.map(
        ({ id, trackingName }: DeviceModel) => ({
          id,
          name: trackingName,
        })
      ),
      deviceTypes: (c.deviceTypes ?? ([] as any)).models.map(
        ({ id, name }: DeviceTypeModel) => ({
          id,
          name,
        })
      ),
      manufacturers: (c.manufacturers ?? ([] as any)).models.map(
        ({ id, name }: ManufacturerModel) => ({
          id,
          name,
        })
      ),
      operatingSystems: (c.operatingSystems ?? ([] as any)).models.map(
        ({ id, name }: OperatingSystemModel) => ({
          id,
          name,
        })
      ),
      operatingSystemReleases: (
        c.operatingSystemReleases ?? ([] as any)
      ).models.map(({ id, name }: OperatingSystemModel) => ({
        id,
        name,
      })),
      operatingSystemVersions: (
        c.operatingSystemVersions ?? ([] as any)
      ).models.map(({ id, name }: OperatingSystemVersionModel) => ({
        id,
        name,
      })),
    }));
  });
};

export const createSeeds = (server: ServerWithRegistry) => {
  // no seeds for now
};
