import { Model, Factory } from 'miragejs';

import { ServerWithRegistry } from '../server';

type ModelWithTraits = ClientModel & {};

export const model = Model.extend<ClientModel>(
  // @ts-expect-error
  {}
);

export const factory = Factory.extend<ModelWithTraits, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: 'ozmo',
  defaultLocale: 'en-ca',
  defaultLocaleId: 3,
  displayName: 'Ozmo',
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/clients/:id', (schema, request) =>
    schema.find('client', request.params.id)
  );
  server.get('/config/clients/', (schema) => schema.all('client'));
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.create('client');
};
