import {
  Model,
  Factory,
  hasMany,
  Response,
  Request,
  Collection,
} from 'miragejs';
import { ModelInstance, HasMany } from 'miragejs/-types';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';
import { SerializerInterface } from 'miragejs/serializer';

import { ServerWithRegistry } from '../server';
import { applicationSerializer } from '../serializer'; // Required for the custom serializer

type ModelWithRelationships = Omit<SpaceModel, 'clients'> & {
  clients: ModelInstance<ClientModel>[] | HasMany<'client'>;
  traits?: {
    withMultipleClients: boolean;
  };
};

// clientId is added and inferred automagically by mirage because client is an assocation
// therefore omit it from the factory typedef, otherwise it will complain when we don't set it
type FactoryModel = Omit<ModelWithRelationships, 'clients'> & {
  clients: ModelInstance<ClientModel>[];
};

const serializeClient = ({
  id,
  name,
  displayName,
}: ClientModel): SpaceClient => ({ id, name, displayName });

export const model = Model.extend<ModelWithRelationships>({
  clients: hasMany<'client'>(),
} as any);

export const factory = Factory.extend<FactoryModel, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `Space ${i}`,

  clients: [],

  afterCreate: (space, server) => {
    const { traits: { withMultipleClients } = {} } = space;

    space.update({
      clients: server.createList('client', withMultipleClients ? 2 : 1),
    });

    space.update({ name: `${space.name} space` });
  },
});

// Override the serializer for this model because the Ozmo API returns a condensed object representing
// each client
export const serializer = applicationSerializer.extend({
  serialize(
    space:
      | Collection<ModelInstance<FactoryModel>>
      | ModelInstance<FactoryModel>,
    request: Request
  ) {
    // @ts-expect-error
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [space, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(space)) {
      return (serializedData as FactoryModel[]).map((o, i) => {
        return {
          ...o,
          // Ozmo API returns the serialized client, NOT the client object
          clients: (space.models[i]
            .clients as any).models.map((c: ModelInstance<ClientModel>) =>
            serializeClient(c)
          ),
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...space.attrs,
      ...serializedData,
      // Ozmo API returns the serialized client, NOT the client object
      client: (space.clients as any).models.map(
        (c: ModelInstance<ClientModel>) => serializeClient(c)
      ),
    };
  },
});

export const serializeIfExists = (
  space: ModelInstance<SpaceModel> | undefined,
  request: Request,
  context: {} | SerializerInterface
) => {
  if (space) {
    return (serializer as any).prototype.serialize.apply(context, [
      space,
      request,
    ]);
  }
};

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/authoring/spaces/:id', (schema, request) =>
    schema.find('space', request.params.id)
  );
  server.get('/authoring/spaces/', (schema) => schema.all('space'));

  server.patch('authoring/spaces/:id', (schema, request) => {
    const { id } = request.params;
    const body = JSON.parse(request.requestBody);

    const space = schema.find('space', id);
    if (!space) {
      return new Response(404);
    }

    space.update(body);
    return space;
  });
  server.post('authoring/spaces', (schema, request) => {
    const body = JSON.parse(request.requestBody);
    // MirageJS expects there to be a camelCased FK
    body.clientId = body.client_id;

    return schema.create('space', body);
  });

  server.del('authoring/spaces/:id', (schema, request) => {
    const space = schema.find('space', request.params.id);

    if (!space) {
      return new Response(404);
    }
    space.destroy();
    return new Response(204);
  });
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.createList('space', 6, {
    traits: {
      withMultipleClients: false,
    },
  });
};
