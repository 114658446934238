import { Model, Factory, belongsTo, association, Collection } from 'miragejs';
import { ModelInstance } from 'miragejs/-types';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';

import { ServerWithRegistry } from '../server';
import { applicationSerializer } from '../serializer';

export type ModelType = Omit<
  OperatingSystemVersionModel,
  'operatingSystem' | 'operatingSystemRelease'
> & {
  operatingSystem: ModelInstance<OperatingSystemModel>;
  operatingSystemRelease: ModelInstance<OperatingSystemReleaseModel>;
};

type FactoryType = Omit<
  ModelType,
  'operatingSystemId' | 'operatingSystemReleaseId'
>;

export const model = Model.extend<ModelType>({
  operatingSystem: belongsTo<'operatingSystem'>() as any,
  operatingSystemRelease: belongsTo<'operatingSystemRelease'>() as any,
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `1.0.${i}`,
  displayOrder: 0,

  operatingSystem: association(),
  operatingSystemRelease: association(),
});

export const serializer = applicationSerializer.extend({
  serialize(
    operatingSystemVersion:
      | Collection<ModelInstance<FactoryType>>
      | ModelInstance<FactoryType>,
    request: Request
  ) {
    // @ts-expect-error
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [operatingSystemVersion, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(operatingSystemVersion)) {
      return (serializedData as FactoryType[]).map((o, i) => {
        return {
          ...o,
          operatingSystem:
            operatingSystemVersion.models[i].operatingSystem.name,
          operatingSystemRelease:
            operatingSystemVersion.models[i].operatingSystemRelease.name,
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...operatingSystemVersion.attrs,
      ...serializedData,
      operatingSystem: operatingSystemVersion.operatingSystem.name,
      operatingSystemRelease:
        operatingSystemVersion.operatingSystemRelease.name,
    };
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/operating_system_versions/:id', (schema, request) =>
    schema.find('operatingSystemVersion', request.params.id)
  );
  server.get('/config/operating_system_versions/', (schema) =>
    schema.all('operatingSystemVersion')
  );
};
