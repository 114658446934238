import {
  Collection,
  Model,
  Factory,
  ModelInstance,
  belongsTo,
  association,
  hasMany,
  Response,
} from 'miragejs';

import { ServerWithRegistry } from '../server';

export type ModelType = Omit<CategoryModel, 'contentEntries'> & {
  collection2: ModelInstance<CollectionModel>;
  contentEntries: Collection<ContentEntryModel>;
};

type FactoryType = Partial<CategoryModel> & {
  collection2: ModelInstance<CollectionModel>;
  contentEntries?:
    | Collection<ContentEntryModel>
    | ModelInstance<ContentEntryModel>[];
};

export const model = Model.extend<ModelType>({
  collection2: belongsTo<'collection2'>(),
  contentEntries: hasMany<'contentEntry'>(),
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `Category ${i}`,

  // parent: association(),
  collection2: association(),
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get(
    '/authoring/collections/:collection_id/categories/:id',
    (schema, request) =>
      schema.find('category', request.queryParams?.id as string)
  );

  server.get(
    '/authoring/collections/:collection_id/categories',
    (schema, request) =>
      schema.where('category', {
        collectionId: parseInt(
          (request.queryParams?.collection_id ?? '-1') as string,
          10
        ),
      })
  );

  server.patch(
    '/authoring/collections/:collection_id/categories/:id',
    (schema, request) => {
      const {
        params: { id: categoryId },
        requestBody,
      } = request;

      const category = schema.find('category', categoryId);
      if (!category) return new Response(404);

      category?.update(JSON.parse(requestBody));
      return category;
    }
  );
  server.post(
    '/authoring/collections/:collection_id/categories',
    (schema, request) => {
      const {
        params: { collection_id: collectionId },
        requestBody,
      } = request;

      const collection = schema.find('collection2', collectionId);
      if (!collection) return new Response(404);

      const { 'Content-Type': contentType } = request.requestHeaders;
      if (contentType === 'application/x-ndjson') {
        return {
          job: `jobid-123-456`,
        };
      }

      const newCategory = schema.create('category', JSON.parse(requestBody));
      const newCategories = collection?.categories.add(newCategory);

      collection?.update({
        categories: newCategories,
      });

      return collection;
    }
  );

  server.delete(
    '/authoring/collections/:collection_id/categories/:id',
    (schema, request) => {
      const {
        params: { collection_id: collectionId, id: categoryId },
      } = request;
      const category = schema.find('category', categoryId);
      const collection = schema.find('collection2', collectionId);

      if (!category || !collection) return new Response(404);

      const newCategories = collection?.categories?.filter(
        (cat: any) => cat.id !== Number(collectionId)
      );

      collection.update({
        categories: newCategories,
      });

      category.destroy();
      return new Response(204);
    }
  );

  server.post(
    '/authoring/collections/:collection_id/categories/:category_id/content_entries',
    (schema, request) => {
      const {
        params: { category_id: categoryId },
        requestBody,
      } = request;
      const { content_entry_id: contentEntryId } = JSON.parse(requestBody);

      const category = schema.find('category', categoryId);
      const contentEntry = schema.find('contentEntry', contentEntryId);
      if (category && contentEntry) {
        const contentEntries = category?.contentEntries.add(
          contentEntry as any
        );
        category.update({ contentEntries });
        return new Response(201);
      }

      return new Response(404);
    }
  );

  server.delete(
    '/authoring/collections/:collection_id/categories/:category_id/content_entries/:content_entry_id',
    (schema, request) => {
      const {
        params: { category_id: categoryId, content_entry_id: contentEntryId },
      } = request;
      const category = schema.find('category', categoryId);
      if (category) {
        const contentEntries = category?.contentEntries.filter(
          (ce: any) => Number(ce.id) !== Number(contentEntryId)
        );
        category.update({ contentEntries });
        return new Response(204);
      }

      return new Response(404);
    }
  );
};

export const createSeeds = (server: ServerWithRegistry) => {
  // no seeds for now
};
