import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import startPlaywrightMirageServer from 'test-utils/mock-api/playwright-server';

import AppRouter from './routes';
import reportWebVitals from './reportWebVitals';

// if playwright is running, we need to start the mock server.
// It's saved on the window so we can access it in the tests.
if (import.meta.env.VITE_USE_MIRAGEJS) {
  startPlaywrightMirageServer();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <AppRouter />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
