import { Model, Factory, Response } from 'miragejs';

import { ServerWithRegistry } from '../server';

export const model = Model.extend<TopicModel>({} as any);

export const factory = Factory.extend<TopicModel, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  title: (i) => `Mock title ${i}`,
  description: (i) => `Mock description ${i}`,
  slug: (i) => `topic-slug-${i}`,
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/authoring/topics/:id', (schema, request) =>
    schema.find('topic', request.params.id)
  );
  server.get('/authoring/topics/', (schema) => schema.all('topic'));

  server.patch('authoring/topics/:id', (schema, request) => {
    const { id } = request.params;
    const body = JSON.parse(request.requestBody);

    const topic = schema.find('topic', id);
    if (!topic) {
      return new Response(404);
    }

    topic.update(body);
    return topic;
  });

  server.post('authoring/topics', (schema, request) => {
    const body = JSON.parse(request.requestBody);
    return schema.create('topic', body);
  });

  server.del('authoring/topics/:id', (schema, request) => {
    const topic = schema.find('topic', request.params.id);

    if (!topic) {
      return new Response(404);
    }
    topic.destroy();
    return new Response(204);
  });
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.createList('topic', 6);
};
