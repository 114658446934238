import { Model, Factory } from 'miragejs';

import { ServerWithRegistry } from '../server';

export const model = Model.extend<ManufacturerModel>(
  // @ts-expect-error
  {}
);

export const factory = Factory.extend<ManufacturerModel, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `manufacturer name ${i}`,
  makesApps: (i) => [true, false][i % 2],
  makesDevices: (i) => [false, true][i % 2],
  logoImagePath: '',
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/manufacturers/:id', (schema, request) =>
    schema.find('manufacturer', request.params.id)
  );
  server.get('/config/manufacturers/', (schema) => schema.all('manufacturer'));
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.createList('manufacturer', 3);
};
