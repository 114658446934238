import {
  Model,
  Factory,
  hasMany,
  ModelInstance,
  Response,
  Collection,
} from 'miragejs';
import { HasMany } from 'miragejs/-types';

import { ServerWithRegistry } from '../server';

type SharedModelAndFactory = Omit<MediaEntryModel, 'locales'> & {
  traits?: {
    withLocales?: boolean;
  };
};

// Model hasMany properties return a Collection of <Model>
type ModelWithRelationships = SharedModelAndFactory & {
  locales:
    | Collection<LocaleModel>
    | ModelInstance<LocaleModel>[]
    | HasMany<'locale'>;
};

// Factory methods for hasMany properties take an array of <Model>
type FactoryWithRelationships = SharedModelAndFactory & {
  locales: ModelInstance<LocaleModel>[];
};

export const model = Model.extend<ModelWithRelationships>({
  locales: hasMany<'locale'>(),
} as any);

export const factory = Factory.extend<
  FactoryWithRelationships,
  ServerWithRegistry
>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `mock-filename-${i}.jpg`,
  mediaType: 'image',
  mediaTypeId: 1,
  size: () => 1024,
  s3Path: () => `mock/path/to/s3`,
  contentHash: () => 'DEAD-BEEF',
  metadata: {},
  extension: () => 'jpg',
  locales: [],

  afterCreate(mediaEntry, server) {
    const { withLocales } = mediaEntry.traits ?? {};

    if (withLocales) {
      mediaEntry.update({
        locales: server.createList('locale', 2),
      });
    }
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/authoring/media_entries/:id', (schema, request) =>
    schema.find('mediaEntry', request.params.id)
  );
  server.get('/authoring/media_entries/', (schema) => schema.all('mediaEntry'));
  server.patch('authoring/media_entries/:id', (schema, request) => {
    const { id } = request.params;
    const body = JSON.parse(request.requestBody);

    const mediaEntry = schema.find('mediaEntry', id);
    if (!mediaEntry) {
      return new Response(404);
    }

    mediaEntry.update(body);
    return mediaEntry;
  });
  server.post('/authoring/media_entries', (schema, request) => {
    let body = {};
    try {
      // Check if the request body is a FormData object
      if (request.requestBody instanceof FormData) {
        for (const [key, value] of request.requestBody.entries()) {
          // data is a File object, so extract the relevant properties
          if (key === 'data') {
            body['name'] = value.name;
            body['size'] = value.size;
            body['s3Path'] = `mock/path/to/s3/${value.name}`;
          } else {
            body[key] = value;
          }
        }
      } else {
        body = JSON.parse(request.requestBody);
      }
    } catch (e) {
      console.error('Error parsing request body:', e);
    }

    return schema.create('mediaEntry', body);
  });
  server.post('authoring/media_entries/:id/copy', (schema, request) => {
    const { id } = request.params;
    return schema.find('mediaEntry', id);
  });
  server.del('authoring/media_entries/:id', (schema, request) => {
    schema.find('mediaEntry', request.params.id)?.destroy();
    return new Response(204);
  });
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.createList('mediaEntry', 5);
};
