import { Model, Factory } from 'miragejs';

import { ServerWithRegistry } from '../server';

type ModelWithTraits = DeviceTypeModel;

export const model = Model.extend<DeviceTypeModel>(
  // @ts-expect-error
  {}
);

export const factory = Factory.extend<ModelWithTraits, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',
  name: (i) => `device type name ${i}`,
  isIot: false,
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/device_types/:id', (schema, request) =>
    schema.find('deviceType', request.params.id)
  );
  server.get('/config/device_types/', (schema) => schema.all('deviceType'));
};
