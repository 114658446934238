import {
  Model,
  Factory,
  ModelInstance,
  belongsTo,
  association,
  Collection,
} from 'miragejs';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';

import { ServerWithRegistry } from '../server';
import { applicationSerializer } from '../serializer'; // Required for the custom serializer

// Mirage override types for specific models
import { ModelType as MirageDeviceModel } from './device';

type ModelType = Omit<DeviceShellModel, 'device'> & {
  device: ModelInstance<MirageDeviceModel>;
};

type FactoryType = Omit<DeviceShellModel, 'deviceId'>;

export const model = Model.extend<ModelType>({
  device: belongsTo() as any,
} as any);

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-04-12',
  updatedAt: () => '2023-04-12',

  name: (i) => `Device Shell ${i}`,
  x: () => 50,
  y: () => 50,
  height: () => 800,
  width: () => 600,
  imagePath: '',
  overlayImagePath: '',
  default: false,

  device: association(),
});

// Override the serializer for this model because the Ozmo API returns the string name
// of the Client instead of the actual client model, unlike most authoring relations
export const serializer = applicationSerializer.extend({
  serialize(
    deviceShell:
      | Collection<ModelInstance<ModelType>>
      | ModelInstance<ModelType>,
    request: Request
  ) {
    // @ts-expect-error
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [deviceShell, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(deviceShell)) {
      return (serializedData as ModelType[]).map((o, i) => {
        return {
          ...o,
          device: deviceShell.models[i].device.trackingName,
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...deviceShell.attrs,
      ...serializedData,
      device: deviceShell.device.trackingName,
    };
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get('/config/devices/:deviceId/shells/:id', (schema, request) =>
    schema.find('deviceShell', request.params.id)
  );
  server.get('/config/devices/:deviceId/shells/', (schema, request) => {
    const deviceId = parseInt(request.params.deviceId, 10);
    return schema.where('deviceShell', { deviceId });
  });
};

export const createSeeds = (server: ServerWithRegistry) => {
  server.createList('deviceShell', 4, { default: true });
};
